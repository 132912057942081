import './App.css';
import {Route, Routes} from "react-router-dom";
import NotFound from "./page/error/notFound";
import MainPage from "./page/main/mainPage";
import Walk from "./page/walk/walk";
import Diary from "./page/diary/diary";
import Nearby from "./page/nearby/nearby";
import Menu from "./component/menu";
import Register from "./page/register/register";
import RegisterForm from "./page/register/registerForm";
import RegisterComplete from "./page/register/complete";
import Login from "./page/auth/login";

function App() {

  return (
    <div className="App">
        <Routes>
            <Route path="/" element={<Menu />}>
                <Route index element={<MainPage />} />
                <Route path="walk" element={<Walk />} />
                <Route path="diary" element={<Diary />} />
                <Route path="nearby" element={<Nearby />} />
            </Route>
            <Route path="/register" element={<Register />} />
            <Route path="/register/form" element={<RegisterForm />} />
            <Route path="/register/complete" element={<RegisterComplete />} />
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    </div>
  );
}

export default App;
