import React from 'react';

function historyBack(){
    window.history.back();
}
function TopNavi(props) {
    return(
        <div className="topArwNavi">
            <div className="top" onClick={historyBack}><span><i className="fa-solid fa-chevron-left"></i>{props.title}</span></div>
            <div className="blank"></div>
        </div>
    );
}

export default TopNavi;
