import React from "react";
import Lottie from "lottie-react";
import alertLottie from "../../asset/json/lottie_404.json";

function NotFound(){
    return(
        <div className="notFoundWrap">
            <div className="ani">
                <Lottie animationData={alertLottie} />
            </div>
            <div className="title">404 ERROR</div>
            <div className="tit">Page Not Found</div>
            <div className="txt">
                The link you followed probably broken.<br/>
                or the page gas been removed.
            </div>
        </div>
    );
}

export  default NotFound;