import React from "react";
import mainData from "../../asset/json/main.json";
import TopBar from "../../component/topBar";
import Lottie from "lottie-react";
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import 'swiper/css/pagination';

// 동적으로 JSON 파일을 가져오는 함수
const loadAnimationData = (weatherState) => {
    try {
        return require(`../../asset/json/weather/${weatherState}.json`);
    } catch (error) {
        console.error(`Error loading animation for state: ${weatherState}`, error);
        return null;
    }
};

function MainPage() {
    const weatherState = mainData[0].weatherInfo[0].state;
    const animationData = loadAnimationData(weatherState);

    return (
        <>
            <TopBar />
            <div className="slideBanner">
                <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    loop={true}
                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: false
                    }}
                    speed={1000}
                    modules={[Autoplay]}
                >
                    {mainData[0].mainBanner.length > 0 && mainData[0].mainBanner.map((info, index) => (
                        <SwiperSlide key={index} className="swiper-slide">
                            <img src={info.imgUrl} style={{ width: '100%' }} alt="" onClick={() => { window.open(info.link) }} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>

            <div className="mainWeather sbox">
                <div className="tit titleDefault">오늘의 산책날씨를 알아 볼까요?</div>
                <div className="info">
                    <i className="fa-solid fa-location-dot"></i>
                    <span className="location">{mainData[0].weatherInfo[0].location}</span>, <span className="state">{mainData[0].weatherInfo[0].stateText}</span>
                    <span className="temperature">{mainData[0].weatherInfo[0].temperature}℃</span>
                </div>
                <div className="ico">{animationData && <Lottie animationData={animationData} style={{ width: '90px' }} />}</div>
                <div className="dust">
                    {mainData[0].weatherInfo[0].dust.map((info, index) => (
                        <ul key={index}>
                            <li className={"state " + info.state}>{info.text}</li>
                            <li className="type">{info.type}</li>
                            <li className="value">{info.value}</li>
                        </ul>
                    ))}
                </div>
            </div>

            <div className="mainPetInfo sbox">
                {mainData[0].petInfo.length > 0 ? (
                    <>
                        <div className="mainMore"><i className="fa-solid fa-plus"></i></div>
                        <Swiper
                            spaceBetween={0}
                            slidesPerView={1}
                            loop={true}
                            speed={500}
                            pagination={{ clickable: true }}
                            modules={[Pagination]}
                        >
                            {mainData[0].petInfo.map((data, index) => (
                                <SwiperSlide key={index} className="swiper-slide">
                                    <div className="pet">
                                        <ul className="thumb"><img src={data.profileImage} alt={data.name} /></ul>
                                        <ul className="profile"><span className="name titleDefault">{data.name}</span><span className="age">{data.gender}{data.age}세</span></ul>
                                        <ul className="date">마지막산책일<span>2023.12.20 15:00</span></ul>
                                        <ul className="info">
                                            {data.info.map((detail, detailIndex) => (
                                                <li key={detailIndex}>
                                                    <dl className="ico"><div className={"setIcon_"+detail.type}></div></dl>
                                                    <dl className="type">{detail.typeText}</dl>
                                                    <dl className="value">{detail.value}</dl>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </>
                ) : (
                    <div className="noneBox">
                        <ul className="txt">등록된 펫이 없습니다.</ul>
                        <ul className="btn">펫등록하기</ul>
                    </div>
                )}
            </div>

            <div className="mainDiary sbox">
                <div className="mainMore"><i className="fa-solid fa-plus"></i></div>
                <div className="titleDefault">다이어리</div>
                {mainData[0].diary.length > 0 ? (
                    <div className="list">
                        {mainData[0].diary.map((data, index) => (
                            <ul key={index}>
                                <li className="thumb">
                                    {data.image !== "" ? (
                                        <img src={data.image} alt="" />
                                    ) : (
                                        <div className={"ico setIcon_" + data.type}></div>
                                    )}
                                </li>
                                <li className="title">{data.title}</li>
                                <li className="date">{data.date}</li>
                            </ul>
                        ))}
                    </div>
                ) : (
                    <div className="noneBox">
                        <ul className="txt">등록된 내용이 없습니다.</ul>
                        <ul className="btn">다이어리 글쓰기</ul>
                    </div>
                )}
            </div>

            <div className="mainNotice sbox">
                <ul className="tit titleDefault">공지</ul>
                <div className="swiper-container list">
                    <Swiper
                        direction="vertical" // 세로 방향으로 슬라이드
                        spaceBetween={20}
                        slidesPerView={1}
                        loop={true}
                        autoplay={{
                            delay: 2000,
                            disableOnInteraction: false
                        }}
                        speed={1000}
                        modules={[Autoplay]}
                        className="swiper-wrapper"
                    >
                        {mainData[0].notice.map((data, index) => (
                            <SwiperSlide key={index} className="swiper-slide">
                                <li className="list-item">{data.title}</li>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>

        </>
    );
}

export default MainPage;