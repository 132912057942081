import React from "react";
import TopBar from "../../component/topBar";

function Nearby(){
    return(
        <>
            <TopBar />
            <div className="titleDefault">주변</div>
        </>
    );
}

export  default Nearby;