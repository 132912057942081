import React from 'react';

function LayerPop({ open, title, contents, onClose }) {
    if (!open) return null;
    return(
        <div className="layerPop">
            <div className="inBox">
                <div className="title">{title}</div>
                <div className="close" onClick={onClose}><i className="fa-solid fa-xmark"></i></div>
                <div className="contents">
                    {contents}
                </div>
            </div>
            <div className="bg" onClick={onClose}></div>
        </div>
    );
}

export default LayerPop;
