import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import profileDefault from  "../asset/images/profile_default.png"

function Menu() {
    const location = useLocation();
    const isActive = (path) => {
        if (path === "/") {
            return location.pathname === "/";
        }
        return location.pathname.startsWith(path);
    };

    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
        window.history.pushState(null, null, window.location.href);
    };

    useEffect(() => {
        const handlePopState = () => {
            if (isOpen) {
                setIsOpen(false);
            }
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [isOpen]);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);

    // 하단메뉴 Hidden
    const hiddenPaths = ["/abc","/1234"];
    const shouldHideMenu = hiddenPaths.includes(location.pathname);

    return (
        <>
            <Outlet />
            {!shouldHideMenu && (
                <>
                    <div className="botMenu">
                        <ul className={isActive("/") ? "on" : ""}>
                            <Link to="/">
                                <div className="ico"><i className="fa-solid fa-house"></i></div>
                                <div className="txt">홈</div>
                            </Link>
                        </ul>
                        <ul className={isActive("/walk") ? "on" : ""}>
                            <Link to="/walk">
                                <div className="ico"><i className="fa-solid fa-person-walking"></i></div>
                                <div className="txt">산책</div>
                            </Link>
                        </ul>
                        <ul className={isActive("/diary") ? "on" : ""}>
                            <Link to="/diary">
                                <div className="ico"><i className="fa-solid fa-book"></i></div>
                                <div className="txt">다이어리</div>
                            </Link>
                        </ul>
                        <ul className={isActive("/nearby") ? "on" : ""}>
                            <Link to="/nearby">
                                <div className="ico"><i className="fa-solid fa-map-location-dot"></i></div>
                                <div className="txt">주변</div>
                            </Link>
                        </ul>
                        <ul onClick={toggleMenu}>
                            <i className="fa-solid fa-ellipsis"></i>
                        </ul>
                    </div>
                    <div className="botMenuBlank"></div>
                </>
            )}
            <div className={`slideMenu ${isOpen ? 'open' : ''}`}>
                <div className="inBox">
                    <div className="myInfo">
                        <div className="info">
                            <div className="profileImage">
                                <img src={profileDefault} alt=""/>
                            </div>
                            <ul>
                                <div className="nickName"><span>모리노아</span>님</div>
                                <div className="authentication">미실명</div>
                            </ul>
                            <ul>
                                <li>펫<span>2</span></li>
                                <li>공유집사<span>4</span></li>
                                <li>공유펫<span>1</span></li>
                            </ul>
                        </div>
                        <div className="arw"><i className="fa-solid fa-chevron-right"></i></div>
                    </div>
                    <div className="menu">
                        <ul><i className="fa-solid fa-person-walking"></i>산책</ul>
                        <ul><i className="fa-solid fa-book"></i>다이어리</ul>
                        <ul><i className="fa-solid fa-map-location-dot"></i>주변</ul>
                        <ul><i className="fa-solid fa-chart-pie"></i>리포트</ul>
                        <ul><i className="fa-solid fa-user-gear"></i>공유집사관리</ul>
                        <ul><i className="fa-solid fa-paw"></i>반려동물관리</ul>
                        <ul><i className="fa-solid fa-headphones"></i>고객센터</ul>
                        <ul><i className="fa-solid fa-list-check"></i>공지사항</ul>
                        <ul><i className="fa-regular fa-bell"></i>알림설정</ul>
                        <ul><i className="fa-solid fa-gear"></i>환경설정</ul>
                    </div>
                    <div className="bot">
                        <ul className="version">V 1.0.0</ul>
                        <Link to="/register"><ul className="logout">로그아웃</ul></Link>
                    </div>
                </div>
                <div className="bg" onClick={toggleMenu}></div>
            </div>
        </>
    );
}

export default Menu;
