import React from "react";
import logo from '../asset/images/logo.svg';

function TopBar(){
    return(
        <>
            <div className="topBar">
                <img className="logo" src={logo} alt="Company Logo"/>
                <div className="alert new"><i className="fa-regular fa-bell"></i></div>
            </div>
            <div className="topBarBlank"></div>
        </>
    );
}

export default TopBar;
