import React from "react";
import TopBar from "../../component/topBar";

function Walk(){
    return(
        <>
            <TopBar />
            <div className="titleDefault">산책</div>
        </>
    );
}

export  default Walk;