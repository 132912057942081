import React from "react";
import TopBar from "../../component/topBar";

function Diary(){
    return(
        <>
            <TopBar />
            <div className="titleDefault">다이어리</div>
        </>
    );
}

export  default Diary;