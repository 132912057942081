import React, {useEffect} from 'react';
import completeLottie from "../../asset/json/registerComplete.json";
import complete from "../../asset/json/complete.json";
import Lottie from "lottie-react";
import {Link} from "react-router-dom";

function RegisterComplete() {

    useEffect(() => {
        document.body.style.backgroundColor = "#fff";
        return () => {
            document.body.style.backgroundColor = "";
        };
    }, []);

    return(
        <div className="registerComplete">
            <div className="box">
                <div className="lottie">
                    <Lottie animationData={completeLottie} />
                </div>
                <div className="text">
                    <ul>회원가입이 완료되었습니다!</ul>
                    <ul>반려동물과 함께<br/><span>위드펫</span>을 경험해보세요~</ul>
                </div>
                <Link to="/"><button className="minWidth">위드펫 시작하기</button></Link>
            </div>
            <div className="cover">
                <Lottie
                    animationData={complete}
                    loop={false}
                />
            </div>
        </div>
    );
}

export default RegisterComplete;
