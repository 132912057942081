import React, { useEffect, useState } from "react";
import logo from "../../asset/images/ico_default.svg";
import kakao from "../../asset/images/ico_kakao.svg";
import naver from "../../asset/images/ico_naver.svg";
import { Checkbox } from "antd";
import LayerPop from "../../component/layerPop";
import {Link, useNavigate} from "react-router-dom";
import Swal from "sweetalert2";
import TermsOfService from "../../component/TermsOfService";
import PrivacyPolicy from "../../component/PrivacyPolicy";

function Register() {
    const [termsChecked, setTermsChecked] = useState(false);
    const [privacyChecked, setPrivacyChecked] = useState(false);
    const [isLayerVisible, setIsLayerVisible] = useState(false);
    const [layerTitle, setLayerTitle] = useState("");
    const [layerContent, setLayerContent] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        document.body.style.backgroundColor = "#fff";
        return () => {
            document.body.style.backgroundColor = "";
        };
    }, []);

    const handleCheckboxChange = (setter) => (e) => {
        setter(e.target.checked);
    };

    const handleRegisterClick = () => {
        if (termsChecked && privacyChecked) {
            navigate("/register/form");
        } else {
            Swal.fire({
                icon: "warning",
                title: "이용동의 확인",
                text: "모든 필수 항목에 동의하셔야 합니다.",
            }).then();
        }
    };

    const showLayerPop = (title, contentComponent) => () => {
        setLayerTitle(title);
        setLayerContent(contentComponent);
        setIsLayerVisible(true);
    };

    const handleCancel = () => {
        setIsLayerVisible(false);
    };

    return (
        <div className="registerWrap">
            <div className="box">
                <div className="inBox">
                    <ul className="txt1">반갑습니다!</ul>
                    <ul className="txt2">
                        <span>위드펫</span>과 함께
                    </ul>
                    <ul className="txt3">시작해볼까요?</ul>
                    <ul className="ico">
                        <img src={logo} alt="" />
                    </ul>
                </div>
            </div>
            <div className="login">
                <ul>이미 위드펫에 가입하셨나요?</ul>
                <Link to="/login"><ul className="btn">로그인하기</ul></Link>
            </div>
            <div className="agree">
                <ul>
                    <Checkbox onChange={handleCheckboxChange(setTermsChecked)}>
                        (필수) 회원 이용약관 동의
                    </Checkbox>
                    <div className="arw" onClick={showLayerPop("회원 이용약관", <TermsOfService />)}>
                        <i className="fa-solid fa-chevron-right"></i>
                    </div>
                </ul>
                <ul>
                    <Checkbox onChange={handleCheckboxChange(setPrivacyChecked)}>
                        (필수) 개인정보 이용 동의
                    </Checkbox>
                    <div className="arw" onClick={showLayerPop("개인정보 이용 동의", <PrivacyPolicy />)}>
                        <i className="fa-solid fa-chevron-right"></i>
                    </div>
                </ul>
            </div>
            <div className="btnType">
                <ul className="kakao">
                    <img src={kakao} alt="" />
                    카카오로 시작하기
                </ul>
                <ul className="naver">
                    <img src={naver} alt="" />
                    네이버로 시작하기
                </ul>
                <ul className="withpet" onClick={handleRegisterClick}>
                    위드펫 회원가입
                </ul>
            </div>
            <LayerPop
                title={layerTitle}
                open={isLayerVisible}
                onClose={handleCancel}
                contents={layerContent}
            />
        </div>
    );
}

export default Register;
