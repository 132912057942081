import React, {useEffect,useState,useRef} from "react";
import TopNavi from "../../component/topNavi";
import normalizePhone from "../../module/normalizePhone";
import Swal from "sweetalert2";
import registerLottie from "../../asset/json/register.json";
import Lottie from "lottie-react";
import {useNavigate} from "react-router-dom";

function RegisterForm(){

    const navigate = useNavigate();

    useEffect(() => {
        document.body.style.backgroundColor = "#fff";
        return () => {
            document.body.style.backgroundColor = "";
        };
    }, []);

    const [isPhoneDisabled, setIsPhoneDisabled] = useState(false);
    const [verificationCode, setVerificationCode] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [showVerification, setShowVerification] = useState(false);
    const [timer, setTimer] = useState(60);
    const [timerActive, setTimerActive] = useState(false);
    const [isVerificationDisabled, setIsVerificationDisabled] = useState(false);
    const nickNameRef = useRef(null);

    const handleSendVerification = () => {
        if (phoneNumber.trim() === "") {
            Swal.fire({
                icon: 'warning',
                text: '휴대폰 번호를 입력해 주세요.',
                confirmButtonText: '확인'
            }).then();
            return;
        }

        if (phoneNumber.trim().length !== 11) {
            Swal.fire({
                icon: 'warning',
                text: '휴대폰 번호는 11자리여야 합니다.',
                confirmButtonText: '확인'
            }).then();
            return;
        }
        setTimerActive(true);
        setShowVerification(true);
        setIsPhoneDisabled(true)
    };

    const handleInputChange = (e) => {
        const { value } = e.target;
        const normalizedValue = normalizePhone(value);
        setPhoneNumber(normalizedValue);
    };

    useEffect(() => {
        let interval;
        if (timerActive) {
            interval = setInterval(() => {
                setTimer((prev) => {
                    if (prev > 0) {
                        return prev - 1;
                    } else {
                        clearInterval(interval);
                        setShowVerification(false);
                        setTimerActive(false);
                        setIsPhoneDisabled(false);
                        Swal.fire({
                            icon: 'warning',
                            text: '인증 시간이 초과되었습니다. 다시 시도해 주세요.',
                            confirmButtonText: '확인'
                        });
                        return 60;
                    }
                });
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [timerActive]);

    const handleVerify = () => {
        if (verificationCode.trim() === "") {
            Swal.fire({
                icon: 'warning',
                text: '인증번호를 입력해주세요.',
                confirmButtonText: '확인'
            }).then();
            return;
        }
        if (verificationCode === '1234') {
            setIsVerificationDisabled(true);
            setTimerActive(false);
            setTimer(60);
        } else {
            Swal.fire({
                icon: 'error',
                text: '인증번호가 맞지 않습니다.',
                confirmButtonText: '확인'
            }).then();
        }
    };

    const handleRegister = () => {
        const nickName = nickNameRef.current.value.trim();
        if (nickName === "") {
            Swal.fire({
                icon: 'warning',
                text: '닉네임을 입력해 주세요.',
                confirmButtonText: '확인'
            }).then();
        } else {
            navigate("/register/complete")
        }
    };

    return(
        <div className="registerForm">
            <TopNavi title="회원가입"/>
            <div className="lottie">
                <Lottie animationData={registerLottie} />
            </div>
            <div className="title">휴대폰번호로<br/>간편하게 가입하세요!</div>
            <div className="formDefault">
                <ul>
                    <input id="nickName" className="default" type="tel" placeholder="닉네임 입력" ref={nickNameRef}/>
                </ul>
                <ul>
                    <input className="default" type="tel" placeholder="휴대폰번호 입력" value={phoneNumber} onChange={handleInputChange} disabled={isPhoneDisabled} />
                    <button className="inputBtn" onClick={handleSendVerification} disabled={isPhoneDisabled}>인증발송</button>
                </ul>
                {showVerification && (
                    <ul>
                        <input className="default" type="tel" placeholder="인증번호 입력 (1234)" value={verificationCode} disabled={isVerificationDisabled} onChange={(e) => setVerificationCode(e.target.value)}/>
                        <div className="certifyNum">{isVerificationDisabled ? "" : `0${Math.floor(timer / 60)}:${(timer % 60).toString().padStart(2, '0')}`}</div>
                        <button className="inputBtn" onClick={handleVerify} disabled={isVerificationDisabled}>인증하기</button>
                    </ul>
                )}
                <button className="default mgt30" disabled={!isVerificationDisabled} onClick={handleRegister}>가입하기</button>
            </div>
        </div>
    );
}

export default RegisterForm;